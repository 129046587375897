<template>
  <div class="btns">
    <vue-json-to-csv
      v-if="dataJson != ''"
      :json-data="dataJson"
      :labels="{
        nameService: { title: 'Serviço' },
        cpfByClient: { title: 'CPF do Cliente' },
        nameBySalesman: { title: 'nome' },
        tenant: { title: 'unidade' },
        serviceDate: { title: 'Data' },
        amount: { title: 'Valor' },
        payments: { title: 'Pagamento' },
      }"
      :csv-title="'Analitico'"
      :separator="';'"
      @success="(val) => handleSuccess(val)"
      @error="(val) => handleError(val)"
    >
      <button class="btn_default">Extrair {{ nome }}</button>
    </vue-json-to-csv>
    <button v-else class="btn_default" @click="$parent.ShowError(msg)">
      Extrair {{ nome }}
    </button>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  props: ["nome", "dataJson", "url", "msg"],
  components: {
    VueJsonToCsv,
  },
  methods: {
    handleSuccess(e) {
      console.log(e);

      //this.$emit('clear', e)
      // @click="$emit('teste')"
    },
    handleError(e) {
      console.log(e, "erro");
    },
  },
};
</script>

<style scoped>
.btns button {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  background-color: #a9a2a2;
  color: #fff;
  margin: 0 10px;
  cursor: pointer;

  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #e0a638;
  border: none;
}

.one {
  margin: 0 10px 0 123px;
}
.btns button:hover {
  background-color: #f38235;
}
</style>

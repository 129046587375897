<template>
  <div>
    <div class="containerItem">
      <h1>Serviços Despachantes Emitidos</h1>
      <form
        class="col-12 p-0 row" id="allMultiCont"
        @submit.prevent="$emit('sendFilter', filter)"
      >
        <div class="col-3 p-1 mr-1 d-flex">
          <div class="multiselec col-12">
            <multiselect
              v-model="valueDispatcher"
              :options="dispatcherOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :searchable="false"
              :maxHeight="150"
              :limit="1"
              :limit-text="limitText"
              placeholder="Despachante"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="valueDispatcher.length && !isOpen"
                  >{{
                    valueDispatcher.length > 1
                      ? `${valueDispatcher.length} selecionados`
                      : `${valueDispatcher.length} selecionado`
                  }}
                </span>
              </template>
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
          </div>
        </div>
        <div class="col-3 p-1 mr-1 d-flex">
          <div class="multiselec col-12">
            <multiselect
              v-model="valueTenant"
              :options="tenantOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :searchable="false"
              :maxHeight="150"
              :limit="1"
              :limit-text="limitText"
              placeholder="Unidade"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="valueTenant.length && !isOpen"
                  >{{
                    valueTenant.length > 1
                      ? `${valueTenant.length} selecionados`
                      : `${valueTenant.length} selecionado`
                  }}
                </span>
              </template>
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
          </div>
        </div>
        <div class="col-2 p-1 mr-1 d-flex">
          <div class="multiselec col-12">
            <multiselect
              v-model="valueStatus"
              :options="statusOptions"
              :loading="loadingStatus"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :searchable="false"
              :maxHeight="150"
              :limit="1"
              :limit-text="limitText"
              placeholder="Status"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="valueStatus.length && !isOpen"
                  >{{
                    valueStatus.length > 1
                      ? `${valueStatus.length} selecionados`
                      : `${valueStatus.length} selecionado`
                  }}
                </span>
              </template>
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
          </div>
        </div>
        <div class="col-6 p-1 d-flex" id="dateDespCont">
          <div class="col-6">
            <input type="date" v-model="filter.dataInit" class="inputData" />
          </div>
          <div class="col-6">
            <input type="date" v-model="filter.dataFinish" class="inputData" />
          </div>
        </div>
        <div class="col-5">
          <button class="inputSubmit">Extrair</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    tenant: {
      type: Array,
      default: []
    },
    status: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      valueDispatcher: [],
      valueTenant: [],
      valueStatus: [],
      statusOptions: [],
      loadingStatus: true,
      filter: {
        tenantIds: "",
        tenantDispatcherIds: "",
        statusServices: "",
        dataInit: "",
        dataFinish: ""
      }
    };
  },
  computed: {
    dispatcherOptions() {
      let tenants = this.tenant.filter(item => item.isDispatcher);
      if (tenants.length) {
        return tenants.map(t => ({ id: t.id, name: t.tenancyName }));
      }

      return [];
    },
    tenantOptions() {
      let tenants = this.tenant;
      if (tenants.length) {
        return tenants.map(t => ({ id: t.id, name: t.tenancyName }));
      }

      return [];
    }
  },
  methods: {
    ...mapActions(["getServiceStatus"]),
    limitText(count) {
      return `e mais ${count}`;
    },
    async getStatus() {
      try {
        const { data } = await this.getServiceStatus();
        const { result } = data;
        this.loadingStatus = false;
        if (result.length) {
          this.statusOptions = result
            .filter(s => s.id > 0)
            .map(t => ({ id: t.id, name: t.name }));
        }
      } catch (error) {
        this.loadingStatus = false;
      }
    }
  },
  mounted() {
    this.getStatus();
  },
  watch: {
    valueStatus() {
      this.filter.statusServices = [];
      if (this.valueStatus.length) {
        this.filter.statusServices = this.valueStatus.map(t => t.id);
      }
    },
    valueTenant() {
      this.filter.tenantIds = [];
      if (this.valueTenant.length) {
        this.filter.tenantIds = this.valueTenant.map(t => t.id);
      }
    },
    valueDispatcher() {
      this.filter.tenantDispatcherIds = [];
      if (this.valueDispatcher.length) {
        this.filter.tenantDispatcherIds = this.valueDispatcher.map(t => t.id);
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
h1 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.containerItem {
  background-color: #175d93;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
}

.containerItem select,
.containerItem input {
  height: 38px;
  padding: 10px;
  width: 80%;
  background: #ffffff;
  border: 1px solid #e9e9eb;
  border-radius: 6px;
  margin: 0 0 10px;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.containerItem select option {
  height: 38px;
  padding: 10px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e9e9eb;
  margin: 0 0 10px;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.inputData {
  width: 40%;
}

.inputSubmit {
  width: 115px !important;
  background: #e0a638 !important;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 4px 0px;
  height: 74ox;
  padding: 13px 0 13px;
  border-radius: 6px;
}

.multiselec {
  display: flex;
  width: 50%;
}
.multiselect {
  height: 60px !important;
}
.multiselect__tag {
  background: #a1e4ff !important;
  color: #113982;
}
.multiselect__tag-icon:after {
  color: black;
}
@media screen and (max-width:767px){
  #allMultiCont{
    display: flex;
    flex-direction: column;
  }
  .col-3{
    max-width: 100%!important;
  }
  .col-2{
    max-width: 100%!important;
  }
  .col-6{
    max-width: 100%!important;
  }
  #dateDespCont{
    flex-direction: column;
  }
}
</style>

<template>
  <div
    :class="
      filterLists.length > 0
        ? 'backgroundContainer heightAnimate'
        : 'backgroundContainer '
    "
  >
    <div class="row">
      <div class="col-12 p-1 m-2 d-flex align-items-center">
        <h2>{{ service }}</h2>
      </div>
    </div>
    <div class="container ">
      <div class="row col-12 p-0" id="rowCont">
        <div
          class="col-4 d-flex justify-content-around align-items-center"
          id="dateCont"
        >
          <div class="labelCont">
            <label v-if="this.sizeWindow <= 767">Data</label>
          </div>
          <div class="inputCont">
            <input
              type="date"
              name="init"
              @blur="changeDate($event, service, 'init')"
              class="ml-3 mr-3"
            />
            <p>a</p>
            <input
              type="date"
              name="finish"
              @blur="changeDate($event, service, 'finish')"
              class="ml-3 mr-3"
            />
          </div>
        </div>

        <div class="col-3 text-center" id="multiCont">
          <div class="labelCont">
            <label v-if="this.sizeWindow <= 767">Unidade</label>
          </div>
          <multiselect
            v-if="service == 'Serviços por Região'"
            v-model="valueCities"
            :options="citiesOptions"
            :multiple="true"
            :loading="loading"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :show-labels="false"
            :searchable="true"
            :maxHeight="180"
            :limit="1"
            :limit-text="limitText"
            group-label="ufName"
            group-values="cities"
            placeholder="Informe a Região"
            label="nameCity"
            track-by="nameCity"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="valueCities.length && !isOpen"
                >{{
                  valueCities.length > 1
                    ? `${valueCities.length} selecionados`
                    : `${valueCities.length} selecionado`
                }}
              </span>
            </template>
            <span slot="noResult">Não encontrada</span>
            <span slot="noOptions">Lista vazia</span>
          </multiselect>
          <select
            v-model="selectedExtration"
            @change="changeDate($event, service, 'select')"
            v-else-if="service === 'Serviços Cadastrados'"
          >
            <option
              v-for="(item, index) in boolean"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
          <select v-model="selected" v-else>
            <option
              v-for="(item, index) in tenants"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-2" id="searchCont">
          <div v-if="search">
            <input
              type="text"
              name=""
              id=""
              class="search"
              placeholder="Nome do serviço"
              @focus="filterListService(filterList)"
              @keyup="filterListService(filterList)"
              v-model="filterList"
            />
            <ul name="" id="" class="input_default_ul">
              <li
                v-for="(item, index) in filterLists"
                :key="index"
                :id="item.id"
                @click="valueCityUl($event.target)"
              >
                {{ item.serviceName }}
              </li>
            </ul>
          </div>
          <multiselect
            class="serviceMulti"
            v-else-if="service == 'Serviços por Região'"
            v-model="valueServices"
            :options="$store.getters.buscaService"
            :multiple="true"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :show-labels="false"
            :searchable="true"
            :maxHeight="180"
            :limit="1"
            :limit-text="limitText"
            placeholder="Serviços"
            label="serviceName"
            track-by="id"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="valueServices.length && !isOpen"
                >{{
                  valueServices.length > 1
                    ? `${valueServices.length} selecionados`
                    : `${valueServices.length} selecionado`
                }}
              </span>
            </template>
            <span slot="noOptions">Lista vazia</span>
            <span slot="noResult">Não encontrado</span>
          </multiselect>
        </div>

        <div
          class="col-3 p-0 d-flex align-items-center d-flex justify-content-end"
          id="buttonsCont"
        >
          <div class="btns one" v-if="btn === '1'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvClente v-else :dataJson="cliente" :msg="msgCliente" />
            </div>
          </div>

          <div class="btns" v-else-if="btn === '2'">
            <div style="display: flex">
              <div v-if="load" style="display: flex; width: 150px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsv
                v-else
                nome="Consolidado"
                :dataJson="consolidado"
                :msg="msgServico"
              />
              <div v-if="load2" style="display: flex; width: 150px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvAnalitico
                v-else
                nome="Analítico"
                :dataJson="analitico"
                :msg="msgServico"
              />
            </div>
          </div>

          <div class="btns" v-else-if="btn === '3'">
            <div>
              <div v-if="load" style="display: flex; width: 135px;">
                <loader color="#e0a638" size="35" />
              </div>
              <ButtonExatraction
                v-else
                :dataJson="extraction"
                :msg="msgExtracao"
              />
            </div>
          </div>
          <div class="btns" v-if="btn === '4'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvOrder v-else :dataJson="orders" :msg="msgOrder" />
            </div>
          </div>
          <div class="btns" v-if="btn === '5'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvIndicators
                v-else
                :dataJson="indicators"
                :msg="msgIndicator"
              />
            </div>
          </div>
          <div class="btns" v-if="btn === '6'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvForRegion
                v-else
                :dataJson="forRegion"
                :msg="msgForRegion"
              />
            </div>
          </div>
          <div class="btns" v-if="btn === '7'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvBB
                v-else
                :dataJson="comissionBB"
                :msg="msgComissionBB"
              />
            </div>
          </div>
          <div class="btns" v-if="btn === '8'">
            <div>
              <div v-if="load" style="display: flex; width: 110px">
                <loader color="#e0a638" size="35" />
              </div>
              <buttonCsvServices
                v-else
                  :dataJson="franchiseeServices"
                  :msg="msgFranchiseeServices"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalDegustacao/>
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
import buttonCsv from "./buttonCsv";
import buttonCsvAnalitico from "./buttonCsvAnalitico";
import buttonCsvClente from "./buttonCsvCliente";
import ButtonExatraction from "./extraction/buttonExatraction.vue";
import buttonCsvOrder from "./pedidosEmitidos/buttonExtractionOrders.vue";
import buttonCsvIndicators from "./indicadores/buttonExtractionIndicators.vue";
import buttonCsvForRegion from "./servicos/buttonExtractionForRegion.vue";
import buttonCsvBB from "./bancodobrasil/buttonExtractionForSalesBB.vue";
import buttonCsvServices from "./buttonCsvServices.vue"
import modalDegustacao from "@/components/modal/modalDegustacao";
import { mapActions } from "vuex";
import HTTP from "@/api_system";
export default {
  props: ["service", "btn", "tenants", "search"],
  components: {
    buttonCsv,
    buttonCsvAnalitico,
    buttonCsvClente,
    loader,
    ButtonExatraction,
    buttonCsvOrder,
    buttonCsvIndicators,
    buttonCsvForRegion,
    buttonCsvBB,
    buttonCsvServices,
    modalDegustacao
  },
  data() {
    return {
      idUnidades: [],
      selected: "",
      citiesOptions: [],
      valueCities: [],
      valueServices: [],
      valueCitiesResult: [],
      valueServicesResult: [],
      consolidado: [],
      analitico: [],
      cliente: [],
      extraction: [],
      orders: [],
      indicators: [],
      forRegion: [],
      comissionBB: [],
      franchiseeServices: [],
      sizeWindow: 0,
      url: "ExportServicesByTenantsConsolidated",
      urlAnalitico: "ExportServicesByTenantsAnalytical",
      init: "",
      finish: "",
      initCliente: "",
      finishCliente: "",
      dateInitExtrator: "",
      dateFinishExtrator: "",
      initOrder: "",
      finishOrder: "",
      initIndicator: "",
      finishIndicator: "",
      initForRegion: "",
      finishForRegion: "",
      initComissionBB: "",
      finishComissionBB: "",
      initFranchiseeService: "",
      finishFranchiseeService: "",
      load: false,
      load2: false,
      loading: true,
      boolean: [
        {
          name: "Sim",
          value: true
        },
        {
          name: "Não",
          value: false
        }
      ],
      selectedExtration: false,
      msgServico: "",
      msgCliente: "",
      msgExtracao: "",
      msgIndicator: "",
      msgForRegion: "",
      msgOrder: "",
      msgForRegion: "",
      msgComissionBB: "",
      msgFranchiseeServices: "",
      filterList: "",
      filterLists: [],
      filterIdValue: ""
    };
  },
  created() {
    this.msgServico = this.msgCliente = this.msgExtracao = this.msgOrder = this.msgIndicator = this.msgForRegion =
      "Por favor, informe os parametros para iniciar a consulta.";
    this.selected = this.tenants[0].id;
    if (this.service === "Serviços Cadastrados") {
      this.exportcvsExtration();
    }

    if (this.service === "Serviços por Região") {
      this.getCityGroup();
      this.exportcvsForRegion();
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    ...mapActions(["getCityForUf"]),
    valueCityUl(e) {
      this.filterList = e.innerHTML;
      this.filterIdValue = Number(e.id);
      this.filterLists = [];
      this.exportcvsConsolidado();
      this.exportcvsAnalitico();
    },
    filterListService(value) {
      let result = this.$store.getters.buscaService.filter(item => {
        let itemService = item.serviceName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          value.length > 0 &&
          itemService.toUpperCase().includes(value.toUpperCase())
        );
      });

      this.filterLists = result;
    },
    exportcvs() {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        this.exportcvsConsolidado();
        this.exportcvsAnalitico();
        this.msgServico =
          "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
      }
    },
    exportcvsConsolidado() {
      this.load = true;
      if (this.filterList.length < 1) {
        this.filterIdValue = "";
      }
      HTTP.post(
        `services/app/OrdersServices/${this.url}`,
        {
          dateInitial: this.init,
          dateFinale: this.finish,
          tenantsId: this.idUnidades,
          serviceId: this.filterIdValue
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const results = res.data.result;
          this.consolidado = results;
          this.load = false;
        })
        .catch(error => {
          const messageError = error.response.data.error.message;
          if (this.init && this.finish && this.selected) {
            this.ShowError(`${messageError}`);
            this.consolidado = [];
          }
          this.load = false;
        });
    },
    exportcvsAnalitico() {
      this.load2 = true;
      HTTP.post(
        `services/app/OrdersServices/${this.urlAnalitico}`,
        {
          dateInitial: this.init,
          dateFinale: this.finish,
          tenantsId: this.idUnidades,
          serviceId: this.filterIdValue
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const results = res.data.result;
          this.analitico = results;
          this.load2 = false;
        })
        .catch(error => {
          const messageError =
            error.response.data.error.details != null
              ? error.response.data.error.details
              : error.response.data.error.message;

          if (this.init && this.finish && this.selected) {
            this.ShowError(`${messageError}`);
            this.analitico = [];
          }
          this.load2 = false;
        });
    },
    exportcvsCliente() {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        this.load = true;
        HTTP.post(
          `services/app/Client/ExportClientsByTenantsAnalytical?dateInitial=${this.initCliente}&dateFinale=${this.finishCliente}&status=true`,
          this.idUnidades,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        )
          .then(res => {
            const results = res.data.result;
            this.cliente = results;
            this.load = false;
          })
          .catch(error => {
            const messageError =
              error.response.data.error.details != null
                ? error.response.data.error.details
                : error.response.data.error.message;
            if (this.initCliente && this.finishCliente && this.selected) {
              this.ShowError(`${messageError}`);
              this.cliente = [];
            }
            this.load = false;
          });
        this.msgCliente =
          "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
      }
    },
    exportcvsExtration() {
      this.load = true;
      if (this.filterList.length < 1) {
        this.filterIdValue = "";
      }
      HTTP.post(
        `services/app/Service/ExportServiceCategory`,
        {
          dateInitial: this.dateInitExtrator,
          dateFinale: this.dateFinishExtrator,
          allTenants: this.selectedExtration,
          serviceId: this.filterIdValue
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const result = res.data.result;
          this.extraction = result;
          this.load = false;
        })
        .catch(error => {
          const messageError =
            error.response.data.error.details != null
              ? error.response.data.error.details
              : error.response.data.error.message;
          this.ShowError(`${messageError}`);
          this.load = false;
          this.extraction = [];
        });
      this.msgExtracao =
        "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
    },
    exportcvsOrder() {
      this.load = true;
      HTTP.post(
        `services/app/OrdersServices/ExportSalesWithoutPeriod`,
        {
          dateInitial: this.initOrder,
          dateFinale: this.finishOrder,
          tenantsId: this.idUnidades
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const results = res.data.result;
          this.orders = results;
          this.load = false;
        })
        .catch(error => {
          const messageError =
            error.response.data.error.details != null
              ? error.response.data.error.details
              : error.response.data.error.message;
          if (this.initOrder && this.finishOrder && this.selected) {
            this.ShowError(`${messageError}`);
            this.orders = [];
          }
          this.load = false;
        });
      this.msgOrder =
        "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
    },
    exportcvsIndicators() {
      this.load = true;
      HTTP.post(
        `services/app/Indicators/ExportIndicatorHistory`,
        {
          dateInitial: this.initIndicator,
          dateFinale: this.finishIndicator,
          tenantsId: this.idUnidades
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const results = res.data.result;
          this.indicators = results;
          this.load = false;
        })
        .catch(error => {
          const messageError =
            error.response.data.error.details != null
              ? error.response.data.error.details
              : error.response.data.error.message;
          if (this.initOrder && this.finishOrder && this.selected) {
            this.ShowError(`${messageError}`);
            this.forRegion = [];
          }
          this.load = false;
        });
      this.msgForRegion =
        "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
    },
    exportcvsComissionBB() {
      this.load = true;
      HTTP.post(
        `services/app/OrdersServices/ExportSalesWithoutPeriodBB`,
        {
          dateInitial: this.initComissionBB,
          dateFinale: this.finishComissionBB,
          tenantsId: this.idUnidades
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          const results = res.data.result;
          this.comissionBB = results;
          this.load = false;
        })
        .catch(error => {
          const messageError =
            error.response.data.error.details != null
              ? error.response.data.error.details
              : error.response.data.error.message;

          if (this.initComissionBB && this.finishComissionBB && this.selected) {
            this.ShowError(`${messageError}`);
            this.comissionBB = [];
          }
          this.load = false;
        });
      this.msgComissionBB =
        "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
    },
    exportcvsFranchiseeServices() {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        this.load = true;
        HTTP.post(
          `services/app/OrdersServices/ExportServicesByTenants`,
          {
            dateInitial: this.initFranchiseeService,
            dateFinale: this.finishFranchiseeService,
            tenantsId: this.idUnidades
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        )
          .then(res => {
            const results = res.data.result;
            this.franchiseeServices = results;
            this.load = false;
          })
          .catch(error => {
            const messageError =
              error.response.data.error.details != null
                ? error.response.data.error.details
                : error.response.data.error.message;
            if (this.initFranchiseeService && this.finishFranchiseeService && this.selected) {
              this.ShowError(`${messageError}`);
              this.franchiseeServices = [];
            }
            this.load = false;
          });
        this.msgFranchiseeServices =
          "Não foi localizado informaçoes para o filtro aplicado, insira outros parametros para realizar nova consulta.";
      }
    },
    changeDate(event, name, val) {
      let { value } = event.target;
      if (name === "Serviços Vendidos") {
        if (val === "finish") {
          this.finish = value;
        } else if (val === "init") {
          this.init = value;
        }

        if (this.finish !== "" && this.init !== "") {
          if (this.selected !== "") {
            this.exportcvs();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Clientes Cadastrados") {
        if (val === "finish") {
          this.finishCliente = value;
        } else if (val === "init") {
          this.initCliente = value;
        }

        if (this.finishCliente !== "" && this.initCliente !== "") {
          if (this.selected !== "") {
            this.exportcvsCliente();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Serviços Cadastrados") {
        if (val === "finish") {
          this.dateFinishExtrator = value;
        } else if (val === "init") {
          this.dateInitExtrator = value;
        }

        this.exportcvsExtration();
        return;
      }

      if (name === "Pedidos Realizados") {
        if (val === "finish") {
          this.finishOrder = value;
        } else if (val === "init") {
          this.initOrder = value;
        }

        if (this.finishOrder !== "" && this.initOrder !== "") {
          if (this.selected !== "") {
            this.exportcvsOrder();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Indicadores Mensais") {
        if (val === "finish") {
          this.finishIndicator = value;
        } else if (val === "init") {
          this.initIndicator = value;
        }

        if (this.finishIndicator !== "" && this.initIndicator !== "") {
          if (this.selected !== "") {
            this.exportcvsIndicators();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Servicos Por Região") {
        if (val === "finish") {
          this.finishForRegion = value;
        } else if (val === "init") {
          this.initForRegion = value;
        }

        if (this.finishForRegion !== "" && this.initForRegion !== "") {
          if (this.selected !== "") {
            this.exportcvsIndicators();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Serviços Banco do Brasil") {
        if (val === "finish") {
          this.finishComissionBB = value;
        } else if (val === "init") {
          this.initComissionBB = value;
        }

        if (this.finishComissionBB !== "" && this.initComissionBB !== "") {
          if (this.selected !== "") {
            this.exportcvsComissionBB();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }

      if (name === "Relatório Serviços Banco do Brasil") {
        if (val === "finish") {
          this.finishFranchiseeService = value;
        } else if (val === "init") {
          this.initFranchiseeService = value;
        }

        if (this.finishFranchiseeService !== "" && this.initFranchiseeService !== "") {
          if (this.selected !== "") {
            this.exportcvsFranchiseeServices();
            return;
          } else {
            this.ShowError(
              "Por favor, selecione uma unidade para realizar a consulta."
            );
          }
        }
      }
    },
    ShowError(msg) {
      this.toast(`${msg}`, this.$toast.error);
    },
    getCityGroup() {
      this.getCityForUf()
        .then(res => {
          this.loading = false;
          this.citiesOptions = res;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    limitText(count) {
      return `e mais ${count}`;
    }
  },
  watch: {
    selected: function(value) {
      if (value) {
        if (value === "todos") {
          this.idUnidades = [...this.tenants]
            .filter(s => s.id != "todos")
            .map(item => ({ id: item.id }));
        } else {
          this.idUnidades = [{ id: value }];
        }

        if (this.init !== "" && this.finish !== "") {
          this.exportcvs();
          return;
        }

        if (this.initCliente !== "" && this.finishCliente !== "") {
          this.exportcvsCliente();
          return;
        }

        if (this.initOrder !== "" && this.finishOrder !== "") {
          this.exportcvsOrder();
          return;
        }

        if (this.initIndicator !== "" && this.finishIndicator !== "") {
          this.exportcvsIndicators();
          return;
        }

        if (this.initForRegion !== "" && this.finishForRegion !== "") {
          this.exportcvsForRegion();
          return;
        }

        if (this.initComissionBB !== "" && this.finishComissionBB !== "") {
          this.exportcvsComissionBB();
          return;
        }

        if (this.initFranchiseeService !== "" && this.finishFranchiseeService !== "") {
          this.exportcvsFranchiseeServices();
          return;
        }
      } else {
        this.ShowError(`${messageError}`);
      }
    },
    filterList: function(value) {
      if (this.filterLists.length < 1) {
        this.exportcvsConsolidado();
        this.exportcvsAnalitico();
        this.exportcvsExtration();
        this.exportcvsOrder();
        this.exportcvsIndicators();
        this.exportcvsComissionBB();
        this.exportcvsFranchiseeServices();
      }
    },
    valueCities() {
      this.valueCitiesResult = [];
      if (this.valueCities.length) {
        this.valueCitiesResult = this.valueCities.map(x => x.nameCity);
      }
      this.exportcvsForRegion();
    },
    valueServices() {
      this.valueServicesResult = [];
      if (this.valueServices.length) {
        this.valueServicesResult = this.valueServices.map(x => x.id);
      }
      this.exportcvsForRegion();
    }
  }
};
</script>

<style scoped>
.backgroundContainer {
  height: auto;
  transition: 0.2s ease-in-out;
}

.backgroundContainer:nth-child(odd) {
  background-color: #357eb8;
}
.backgroundContainer:nth-child(even) {
  background-color: #175d93;
}

.heightAnimate {
  max-height: 300px;
  min-height: 300px;
  transition: 0.2s ease-in-out;
}

select {
  height: 35px;
  margin: 10px;
  width: 130px;
  padding: 7px;
  outline: none;
  border: none;
}

h2,
p {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px !important;
}
h2 {
  font-size: 22px;
  margin-left: 8px;
}
p {
  font-size: 15px;
}

.one {
  margin: 0 10px 0 123px;
}
.btns button:hover {
  background-color: #ccc;
}

input {
  min-height: 40px;
  display: block;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  outline: none;
  padding: 8px 0 10px 5px;
  width: 169px;
}

select {
  min-height: 40px;
  display: block;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  padding-left: 5px;
  width: 200px;
  outline: none;
}

.search {
  width: 100%;
  margin-top: 9px;
}

.input_default_ul {
  text-decoration: none;
  max-height: 182px;
  width: auto;
  width: 149px;
  overflow: auto;
  padding: 0%;
  margin: 0;
  border-right-style: solid !important;
  border-left-style: solid !important;
  border-right-color: #fff !important;
  border-left-color: #ffff !important;
  border-right-width: 1px !important;
  border-left-width: 2px !important;
  color: #fff;
  position: absolute;
  z-index: 1;
  background: #2474b2;
}

.input_default_ul li {
  display: block;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #fff;
  width: auto;
}

.input_default_ul li:hover {
  background-color: #14476e;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  #rowCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #multiCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    max-width: 100%;
  }
  select {
    margin-left: 5px !important;
  }
  #searchCont {
    max-width: 98%;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-left: -5px !important;
  }
  #searchCont input {
    margin-top: 0 !important;
  }
  #dateCont {
    max-width: 100%;
  }
  #dateCont {
    flex-direction: column;
    padding-left: 0 !important;
  }
  #multiCont {
    flex-direction: column;
  }
  #multiCont select {
    margin-top: 0 !important;
    width: 98%;
  }

  .labelCont {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 17px;
    color: #ffff;
    margin-top: 5px;
  }
  .inputCont {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  #dateCont input {
    margin-left: 5px !important;
    margin-right: 2px !important;
  }
  #buttonsCont {
    max-width: 100%;
    margin-bottom: 6%;
    margin-top: 6%;
    justify-content: flex-start !important;
    margin-left: 9%;
  }
  .btns {
    display: flex;
    justify-content: flex-start;
  }
  .one {
    margin: 0;
    width: 100%;
  }
  .serviceMulti {
    margin-top: 3%;
    width: 102%;
  }
}
</style>

<template>
  <div class=" py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
      <router-link to="/" class="imgWelCont">
        <img
          src="../assets/image/iconesMobile/grayArrow.svg"
          class="grayArrow"
        />
      </router-link>
      <div class="txtWelCont">
        <p>Dados e estatísticas</p>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <h1 class="col-12" v-if="this.sizeWindow > 767">
          Dados e estatísticas
        </h1>
        <div class="container">
          <div class="row" v-if="this.sizeWindow > 767">
            <h3 class="col-2">Base</h3>
            <h3 class="col-3 text-center">Data</h3>
            <h3 class="col-4 ml-3 text-center">Unidade</h3>
          </div>
        </div>
      </div>
      <div class="containerInput">
        <half-circle-spinner
          v-if="loadTenants"
          :animation-duration="1000"
          :size="60"
          color="#fff"
          class="mx-auto"
        />
        <InputDados
          v-else
          v-for="(item, index) in data"
          :key="index"
          :service="item.name"
          :btn="item.btn"
          :tenants="allTenants"
          :search="item.search"
        />
      </div>
    </div>
    <div class="container" v-if="$store.getters.getUser.profile == 1">
      <hr />
      <despachanteRelatorio
        :tenant="getAllTenantsLogin"
        :status="status"
        @sendFilter="exportFilter"
      />
      <half-circle-spinner
        v-if="load"
        :animation-duration="1000"
        :size="60"
        color="#fff"
        class="mx-auto"
      />
      <itensRelatorioVue :items="mountedExcel" v-else />
      <!-- <itensRelatorioVue :items='mountedExcel' v-else/> -->
      <!-- {{getServiceDespachanteMaster}} -->
    </div>
  </div>
</template>

<script>
import InputDados from "@/components/dados/dados_input";
import despachanteRelatorio from "@/components/dados/despachanteRelatorio/index.vue";
import itensRelatorioVue from "@/components/dados/despachanteRelatorio/itensRelatorio.vue";

// import ExtrationData from "@/components/dados/extraction/extractionData.vue";
import HTTP from "@/api_system";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    InputDados,
    despachanteRelatorio,
    itensRelatorioVue,
    HalfCircleSpinner
  },
  data() {
    return {
      allTenants: [],
      status: [],
      userProfile: 0,
      load: false,
      loadTenants: true,
      sizeWindow: 0,
      data: [
        {
          name: "Serviços Vendidos",
          btn: "2",
          search: true
        },
        {
          name: "Clientes Cadastrados",
          btn: "1",
          search: false
        },
        {
          name: "Relatório Serviços Banco do Brasil",
          btn: "8",
          search: false
        }
      ],
      mountedExcel: []
    };
  },
  computed: {
    ...mapGetters([
      "getAllTenantsLogin",
      "getServiceDespachanteMaster",
      "getUser",
      "get_message"
    ]),
    user() {
      return this.$store.getters.getUser.profile;
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.userProfile = this.$store.getters.getUser.profile;
    this.getService();
    this.getStatus();
    this.getTagRequest();
    this.exctrator();
  },
  methods: {
    ...mapMutations(["setTagsAll"]),
    ...mapActions([
      "getTags",
      "getServices",
      "exportMaster",
      "getExcel",
      "getTags",
      "getAllTenants"
    ]),

    selectUnidade() {
      this.getAllTenants()
        .then(res => {
          if (res.activeTenants.length) {
            this.allTenants = res.activeTenants;
            if (this.user === 1) {
              let newAllTenants = [
                {
                  id: "todos",
                  name: "Todos"
                }
              ];
              this.allTenants.forEach(t => newAllTenants.push(t));
              this.allTenants = newAllTenants;

              this.data.splice(2, 1);

              this.data.push({
                name: "Serviços Cadastrados",
                btn: "3",
                search: true
              });
              this.data.push({
                name: "Pedidos Realizados",
                btn: "4",
                search: false
              });
              this.data.push({
                name: "Serviços por Região",
                btn: "6",
                search: false
              });
              this.data.push({
                name: "Indicadores Mensais",
                btn: "5",
                search: false
              });
              this.data.push({
                name: "Serviços Banco do Brasil",
                btn: "7",
                search: false
              });
            }
            this.loadTenants = false;
          }
        })
        .catch(err => {
          this.loadTenants = false;
        });
    },

    getService() {
      this.getServices()
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getStatus() {
      this.getTags()
        .then(res => {
          this.status = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    exportFilter(item) {
      const filters = {
        tenantIds: item.tenantIds,
        tenantDispatcherIds: item.tenantDispatcherIds,
        statusServices: item.statusServices,
        dateInitial: item.dataInit,
        dateFinale: item.dataFinish
      };

      this.exportMaster(filters)
        .then(res => {
          this.exctrator();
        })
        .catch(err => {
          this.toast(err, this.$toast.error);
        });
    },

    async getTagRequest() {
      try {
        const result = await this.getTags();
        const tags = result.data.result;
        this.setTagsAll(tags);
      } catch (error) {
        this.toast("Ops", this.$toast.error);
      }
    },

    exctrator() {
      this.load = true;
      this.getExcel(this.getUser.profile + 1)
        .then(res => {
          const result = res.data.result;
          this.mountedExcel = result;
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          this.$emit("load", false);
          console.log(err);
        });
    }
  },
  watch: {
    userProfile: function() {
      this.selectUnidade();
    },
    get_message: function() {
      if (this.get_message == `11_`) {
        this.exctrator();
      }
    }
  }
};
</script>

<style scoped>
h1,
h2 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

h3 {
  color: #fff;
  font-size: 15px;
}

.containerInput {
  width: 100%;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-color: #357eb8;
  background-color: #357eb8;
  border-style: solid;
  border-width: 0px 3px 0px 3px;
  max-height: 50vh;
}

b {
  color: #ccc;
}

hr {
  height: 2px;
  background: #175d93;
  border-radius: 19px;
  margin: 40px 0;
}
@media screen and (max-width: 767px) {
  .welcomeMobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217abf;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont {
    width: 31%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont {
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .imgWelCont img {
    padding-left: 7%;
    width: 35%;
  }
  .py-5 {
    padding-top: 0 !important;
  }
  .containerInput {
    max-height: unset;
  }
}
</style>

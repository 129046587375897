<template>
  <div class="btns">
    <vue-json-to-csv
      v-if="dataJson != ''"
      :json-data="dataJson"
      :labels="{
        name: { title: 'Nome' },
        cpf: { title: 'CPF' },
        cellPhone: { title: 'Telefone' },
        birthDate: { title: 'Nascimento' },
        registrationDate: { title: 'Data Registro' },
        uf: { title: 'Estado' },
        city: { title: 'Cidade' },
        registrationUser: { title: 'vendedor' },
        tenantName: { title: 'Unidade' }
      }"
      :csv-title="'Clientes'"
      :separator="';'"
      @success="val => handleSuccess(val)"
      @error="val => handleError(val)"
    >
      <button>Extrair {{ nome }}</button>
    </vue-json-to-csv>
    <button v-else class="btn_default" @click="$parent.ShowError(msg)">
      Extrair {{ nome }}
    </button>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  props: ["nome", "dataJson", "msg"],
  components: {
    VueJsonToCsv
  },
  methods: {
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e, "erro");
    }
  }
};
</script>

<style scoped>
.btns button {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  background-color: #a9a2a2;
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  right: -11px;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #e0a638;
  border: none;
}

.one {
  margin: 0 10px 0 123px;
}

.btns button:hover {
  background-color: #f38235;
}

.desable {
  /* background: #392f2f !important; */
}
</style>
